.bagstageLogo {
  margin: auto;
}

.headerSection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 var(--spacing-6xl);
  position: absolute;
  top: 100px;
}

.cardSection {
  display: flex;
  gap: var(--spacing-6xl);
  position: fixed;
  top: 343px;
}

.footerSection {
  position: fixed;
  bottom: 73px;
  padding: 0 var(--spacing-6xl);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerButtonText {
  font: var(--font-h5);
  display: flex;
  gap: var(--spacing-s);
}

.userManagementButton {
  gap: var(--spacing-s);
}

.customLinkGreyText {
  font: var(--font-body-medium);
  color: var(--color-grey-scale-2);
}

.languageSection {
  display: flex;
  align-items: center;
  gap: var(--spacing-s);
}

.selectedLanguage {
  font: var(--font-body-medium);
  line-height: var(--line-height-m);
  color: var(--color-black);
}

@media screen and (max-width: 1800px) {
  .headerSection {
    top: 56px;
  }

  .cardSection {
    top: 160px;
  }

  .footerSection {
    bottom: 40px;
  }
}

@media screen and (min-height: 500px) and (max-height: 700px) {
  .headerSection {
    top: 36px;
  }

  .cardSection {
    top: 150px;
  }

  .footerSection {
    bottom: 28px;
  }
}
