.userProfileContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: var(--spacing-xl);
  gap: 0;
}

.username {
  color: var(--color-black);
  font: var(--font-body-large);
}

.useremail {
  color: var(--color-grey-scale-6);
  font: var(--font-body-medium);
}

.userProfileChip {
  background-color: var(--color-indigo-2);
  color: var(--color-white);
  width: 38px;
  height: 38px;
  border-radius: 100%;
  padding: var(--spacing-s);
  font: var(--font-body-large);
  display: flex;
  justify-content: center;
  align-items: center;
}
