.header {
  width: 100%;
  height: var(--header-height);
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey-scale-3);
  padding: var(--spacing-l);
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  background-color: var(--color-white);
}

.headerSection {
  display: flex;
  gap: var(--spacing-l);
  align-items: center;
}

.dateTimeText {
  font: var(--font-body-medium);
  color: var(--color-black);
}

.headerTextContent {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.breadcrumbText {
  font: var(--font-body-small);
  color: var(--color-grey-scale-6);
}

.currentPageText {
  font: var(--font-h4);
  color: var(--color-black);
}

.clock {
  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-l);
  border-radius: var(--border-radius-s);
  gap: var(--spacing-s);
  font: var(--font-h1);
  line-height: var(--line-height-xl);
  color: var(--color-black);
  background-color: var(--color-grey-scale-5);
  height: 38px;
}
