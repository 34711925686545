.standardButton {
  border-radius: var(--border-radius-m);
  padding: var(--spacing-m) var(--spacing-xl);
  height: 38px;
  display: flex;
  gap: var(--spacing-m);
  justify-content: center;
  align-items: center;
  font: var(--font-h5);
}

.standardButtonPrimary {
  background-color: var(--color-indigo-main);
  color: var(--color-white);
}

.standardButtonPrimary:hover {
  background-color: var(--color-indigo-3);
}

.standardButtonPrimary:focus {
  background-color: var(--color-indigo-4);
  border: 2px solid var(--color-indigo-1-2);
}

.standardButtonPrimary:active {
  background-color: var(--color-indigo-4);
}

.standardButtonPrimary:disabled {
  background-color: var(--color-grey-scale-7);
  color: var(--color-grey-scale-2);
  border: none;
  cursor: not-allowed;
}

/* ------------- standard secondary button ---------------- */

.standardButtonSecondary {
  background-color: var(--color-indigo-1);
  color: var(--color-indigo-main);
  border: 1px solid var(--color-indigo-1-2);
}

.standardButtonSecondary:hover {
  background-color: var(--color-indigo-1-2);
  border: 1px solid var(--color-indigo-1-2);
}

.standardButtonSecondary:focus {
  background-color: var(--color-indigo-1);
  border: 2px solid var(--color-indigo-4);
  color: var(--color-indigo-4);
}

.standardButtonSecondary:active {
  background-color: var(--color-indigo-1-2);
  border: 2px solid var(--color-indigo-4);
  color: var(--color-indigo-main);
}

.standardButtonSecondary:disabled {
  background-color: var(--color-grey-scale-1);
  color: var(--color-grey-scale-8);
  border: none;
}

/* ----------- standard tertiary button ------------ */

.standardButtonTertiary {
  color: var(--color-indigo-main);
}

.standardButtonTertiary:hover {
  background-color: var(--color-indigo-1);
  color: var(--color-indigo-main);
}

.standardButtonTertiary:focus {
  background-color: transparent;
  border: 2px solid var(--color-indigo-4);
  color: var(--color-indigo-4);
}

.standardButtonTertiary:active {
  background-color: var(--color-indigo-1);
  border: 2px solid var(--color-indigo-4);
  color: var(--color-indigo-main);
}

.standardButtonTertiary:disabled {
  background-color: transparent;
  color: var(--color-grey-scale-2);
  border: none;
}

/* ------- icon button ------ */

.standardIcon,
.standardIcon:hover {
  height: 24px !important;
  width: 24px !important;
}

.iconButton {
  border-radius: var(--border-radius-m);
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallIconButton {
  height: 32px !important;
  width: 32px !important;
}

.bigIconButton {
  height: 38px;
  width: 38px;
}

/* ------------ icon primary button ------------------- */

.iconButtonPrimary {
  background-color: var(--color-indigo-1);
  border: 1px solid var(--color-indigo-1-2);
}

.iconButtonPrimary:hover {
  background-color: var(--color-indigo-1-2);
  border: 1px solid var(--color-indigo-1-2);
}

.iconButtonPrimary:focus {
  background-color: var(--color-indigo-1);
  border: 2px solid var(--color-indigo-4);
}

.iconButtonPrimary:active {
  background-color: var(--color-indigo-1-2);
}

.iconButtonPrimary:disabled {
  background-color: var(--color-grey-scale-7);
  border: none;
}

/* ------------- icon secondary button ---------------- */

.iconButtonSecondary {
  background-color: var(--color-white);
  border: 1px solid var(--color-indigo-1-2);
}

.iconButtonSecondary:hover {
  background-color: var(--color-indigo-1);
  border: 1px solid var(--color-indigo-1-2);
}

.iconButtonSecondary:focus {
  background-color: transparent;
  border: 2px solid var(--color-indigo-4);
}

.iconButtonSecondary:active {
  background-color: var(--color-indigo-1);
  border: 2px solid var(--color-indigo-4);
}

.iconButtonSecondary:disabled {
  background-color: transparent;
  border: 1px solid var(--color-grey-scale-1);
}

/* ----------- icon disable button ------------ */

.iconLinkDisablePrimary {
  background-color: var(--color-grey-scale-7);
  border: none;
}

.iconLinkDisableSecondary {
  background-color: transparent;
  border: 1px solid var(--color-grey-scale-2);
}

.iconOnlyLinkDisable {
  background-color: transparent;
}

/* ----------- standard link disable button ------------ */

.standardLinkDisablePrimary {
  background-color: var(--color-grey-scale-7);
  color: var(--color-grey-scale-2);
  border: none;
  cursor: not-allowed;
}

.standardLinkDisableSecondary {
  background-color: var(--color-grey-scale-1);
  color: var(--color-grey-scale-8);
  border: none;
  cursor: not-allowed;
}

.standardLinkDisableTertiary {
  background-color: transparent;
  color: var(--color-grey-scale-2);
  border: none;
  cursor: not-allowed;
}
