.errorContainer {
  display: flex;
  justify-content: center;
}

.bagstageLogo {
  position: absolute;
  top: 131px;
}

.errorWrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  top: calc(50% - var(--header-height));
  color: var(--color-black);
  text-align: center;
}

.errorTextWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xs);
}

.errorTextButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-l);
}

.previousButton {
  padding: var(--spacing-s) var(--spacing-l);
}

.errorCode {
  font: var(--font-bold-extra-large);
}

.errorMessage {
  font: var(--font-body-large);
}

.errorSuggestion {
  font: var(--font-body-medium);
}
