.toast {
  width: 336px;
  min-height: 91px;
  padding: var(--spacing-s);
  border-radius: var(--border-radius-xs) var(--border-radius-m)
    var(--border-radius-m) var(--border-radius-xs);
  border-left-width: 4px;
}

.toastContainer {
  display: flex;
  flex-direction: column-reverse;
  row-gap: var(--spacing-l);
  position: fixed;
  z-index: 9999;
  bottom: 64px;
  right: var(--spacing-l);
}

.toastHeaderSection {
  display: flex;
  justify-content: flex-end;
}

.closeButton {
  height: 16px !important;
  width: 16px !important;
}

.closeButton:hover {
  height: 16px !important;
  width: 16px !important;
}

.toastMessage {
  font: var(--font-body-medium);
}

.errorVariantBg {
  background: var(--color-red-2);
  border-left-color: var(--color-red);
  color: var(--color-red);
}

.notificationVariantBg {
  background: var(--color-indigo-1);
  border-left-color: var(--color-indigo-main);
  color: var(--color-indigo-main);
}

.warningVariantBg {
  background: var(--color-yellow-2);
  border-left-color: var(--color-yellow-4);
  color: var(--color-yellow-4);
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes toast-in-right-remove {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.outOfView {
  /*Adding extra 100ms to fadeOut animation to avoid seeing an issue where for a very small interval the
    * item to be removed is shown while fading out. Its conflicting with the component logic of deleting it from the redux menu.
    */
  animation: toast-in-right-remove 400ms;
}

.intoView {
  animation: toast-in-right 300ms;
}
