.styledIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.globeIconBorder {
  border: 2px solid var(--color-green-0-1);
  background-color: var(--color-green-0-1);
}

.barChartIconBorder {
  border: 2px solid var(--color-orange-0-1);
  background-color: var(--color-orange-0-1);
}

.viewOnlyIconBorder {
  border: 2px solid var(--color-purple-0-1);
  background-color: var(--color-purple-0-1);
}

.qtbMonitorIconBorder {
  border: 2px solid var(--color-blue-0-1);
  background-color: var(--color-blue-0-1);
}
