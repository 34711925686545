.tooltipWrapper {
  position: relative;
}

.tooltipIcon {
  cursor: pointer;
}

.tooltip {
  background: var(--color-white);
  box-shadow: var(--tooltip-shadow);
  border-radius: var(--border-radius-s);
  position: absolute;
  right: 0;
  z-index: 1;
  top: calc(100% + var(--spacing-m));
}

.tooltipContent {
  padding: var(--spacing-m);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-m);
  width: 135px;
}

.tooltipOption,
.tooltipOption:hover,
.tooltipOption:focus,
.tooltipOption:active {
  padding: var(--spacing-m);
  height: 34px;
  color: var(--color-black);
  font: var(--font-body-medium);
  background: transparent;
  border-radius: var(--border-radius-s);
  border: none;
  width: 100%;
  justify-content: flex-start;
}

.tooltipOption:hover {
  background-color: var(--color-grey-scale-1);
}

.tooltipOption:active,
.tooltipOptionSelected {
  background-color: var(--color-indigo-1);
}

.tooltipOption:disabled {
  color: var(--color-grey-scale-2);
  cursor: not-allowed;
}
