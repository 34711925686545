.card {
  border: 1px solid var(--color-grey-scale-1);
  height: fit-content;
  width: 360px;
  box-shadow: var(--card-shadow);
  border-radius: var(--border-radius-m);
  background-color: var(--color-white);
}

.cardHeader {
  padding: var(--spacing-4xl);
  display: flex;
  gap: var(--spacing-xl);
  border-bottom: 1px solid var(--color-grey-scale-1);
  align-items: center;
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
}

.cardListItem {
  display: flex;
  gap: var(--spacing-xl);
  padding: var(--spacing-xl);
  font: var(--font-body-extra-large);
  color: var(--color-black);
  align-items: center;
}

.cardDivider {
  border-color: var(--color-grey-scale-1);
}

.cardHeaderText {
  font: var(--font-label-extra-large);
  line-height: var(--line-height-l);
}

.cardHeaderIcon {
  width: 24px;
  height: 24px;
}
