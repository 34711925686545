:root {
  /* Spacing */
  --spacing-2xs: 4px;
  --spacing-xs: 5px;
  --spacing-s: 8px;
  --spacing-m: 10px;
  --spacing-l: 16px;
  --spacing-xl: 20px;
  --spacing-2xl: 24px;
  --spacing-3xl: 32px;
  --spacing-4xl: 40px;
  --spacing-5xl: 50px;
  --spacing-6xl: 80px;

  /* border radius*/
  --border-radius-xs: 4px;
  --border-radius-s: 5px;
  --border-radius-m: 8px;
  --border-radius-l: 16px;
  --border-radius-xl: 20px;
  --border-radius-5xl: 100px;

  /* line height */
  --line-height-s: 14.52px;
  --line-height-m: 16.94px;
  --line-height-l: 26.63px;
  --line-height-xl: 29.05px;

  /* custom values */
  --header-height: 70px;

  /* colors */
  --color-white: #ffffff;
  --color-black: #151d37;

  /* TODO: sort all greys add missing ones */
  --color-grey-scale-1: #eff1f6;
  --color-grey-scale-2: #9ea6bd;
  --color-grey-scale-3: #c4cad9;
  --color-grey-scale-5: #d9dde8;
  --color-grey-scale-6: #707789;
  --color-grey-scale-7: #e6e9ef;
  --color-grey-scale-8: #acbed2;
  --color-grey-scale-9: #fcfcfd;

  --color-indigo-main: #3358d4;
  --color-indigo-1: #f1f5ff;
  --color-indigo-1-2: #d3dfff;
  --color-indigo-2: #4c5c8f;
  --color-indigo-3: #243e94;
  --color-indigo-4: #5170d9;

  --color-red: #e5484d;
  --color-red-1: #ce2c31;
  --color-red-2: #ffdbdc;

  --color-yellow-dark: #f2994a;
  --color-yellow-light: #fef5ed;
  --color-yellow-2: #fefce9;
  --color-yellow-4: #9e6c00;

  --color-green-dark: #30a46c;
  --color-green-light: #d6f1df;

  --color-blue-dark: #0257f8;
  --color-blue-light: #f0f5ff;

  --color-purple-dark: #bb6bd9;
  --color-purple-light: #f9f1fc;

  /* Opacity variations */
  --color-green-0-1: rgba(111, 207, 151, 0.1);
  --color-grey-scale-0-1: rgba(239, 241, 246, 0.1);
  --color-orange-0-1: rgba(242, 153, 74, 0.1);
  --color-purple-0-1: rgba(187, 107, 217, 0.1);
  --color-blue-0-1: rgba(47, 128, 237, 0.1);
  --color-primary-opacity-0-5: rgba(18, 16, 86, 0.5);

  /* fonts */

  /* Font weight 700 */
  --font-bold-extra-large: 700 128px "Inter";
  --font-bold-medium: 700 12px "Inter";

  /* Font weight 600 */
  --font-h1: 600 24px "Inter";
  --font-h2: 600 20px "Inter";
  --font-h3: 600 18px "Inter";
  --font-h4: 600 16px "Inter";
  --font-h5: 600 14px "Inter";
  --font-h6: 600 11px "Inter";

  /* Font weight 500 */
  --font-body-extra-large: 500 18px "Inter";
  --font-body-large: 500 16px "Inter";
  --font-body-medium: 500 14px "Inter";
  --font-body-small: 500 12px "Inter";

  /* Font weight 400 */
  --font-label-extra-large: 400 22px "Inter";
  --font-label-large: 400 14px "Inter";

  /* shadow */
  --card-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  --tooltip-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.05);
}
